export const contentTableConfig = {
  propList: [
    { prop: 'order_id', label: '订单号' },
    { label: '状态', slotName: 'status' },
    { prop: 'serve_name', label: '服务名称' },
    { prop: 'money', label: '订单金额' },
    { prop: 'business_name', label: '商家信息' },
    { label: '下单客户手机号/姓名', slotName: 'customer' },
    { label: '服务提成', slotName: 'rate' },
    { prop: 'pt_commission', label: '平台佣金' },
    { label: '推广者佣金', slotName: 'spreader' },
    { prop: 'create_time', label: '创建时间' },
    { label: '操作', slotName: 'handler' }
  ]
}
