import { ref } from 'vue'
import PageContent from '@/components/page-content/page-content.vue'
export function usePageSearch() {
  const pageContentRef = ref<InstanceType<typeof PageContent>>()

  const queryBtnClick = (queryInfo: any) => {
    console.log('queryInfo: ', queryInfo);
    const pageName = pageContentRef.value?.pageName
    //特殊处理参数
    const queryInfoCopy = { ...queryInfo }
    if (pageName === 'serve') {
      // 商家服务 搜索栏
      queryInfoCopy.cates1_id = queryInfoCopy.cates[0]
      queryInfoCopy.cates2_id = queryInfoCopy.cates[1]
      delete queryInfoCopy.cates
    }
    pageContentRef.value?.getPageData(queryInfoCopy)
  }
  const resetBtnClick = (queryInfo: any) => {
    pageContentRef.value?.getPageData(queryInfo)
  }
  return [pageContentRef, queryBtnClick, resetBtnClick]
}
